import React from 'react';
import Layout from 'components/Layout';
import Container from 'components/Container';
import Seo from "../../components/Seo";
import {Link} from "gatsby";

import rotate1 from "../../images/neverbounce/neverbounce-banner.png";
import homepage from "../../images/neverbounce/home.png";
import pricing from "../../images/neverbounce/pricing.png";
import sync from "../../images/neverbounce/sync.png";
import clean from "../../images/neverbounce/clean.png";
import macs from "../../images/neverbounce/nbcomps2.png";
import illos from "../../images/neverbounce/illos.png";
import deck from "../../images/neverbounce/deck.png";
import poster from "../../images/neverbounce/poster2.png";
import bag from "../../images/neverbounce/bag1.png";
import logos from "../../images/neverbounce/logos.png";
import cards from "../../images/neverbounce/cards.png";

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
        return () => observer.unobserve(domRef.current);
    }, []);
    return (
            <div
                    className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
                    ref={domRef}
            >
                {props.children}
            </div>
    );
}

class NeverBounce extends React.Component {

    render() {
        return (
                <Layout pageName="neverbounce">
                    <Seo
                            title={'NeverBounce'}
                    />
                    <Container>
                        <section>
                            <div className="extra-large-wrapper mt-16 sm:mt-36">
                                <h1 className="font-serif text-left text-4xl sm:text-9xl leading-none sm:-mt-4">NeverBounce</h1>
                                <div className="sm:flex justify-between mt-16 sm:mt-24">
                                    <div className="flex">
                                        <div className="">
                                            <h3 className="font-mono italic text-sm featured-color">Role</h3>
                                            <ul className="w-auto mt-2 inline-flex flex-col items-start">
                                                <li className="border rounded-full py-1 px-4 w-auto font-mono italic text-sm">Brand Identity</li>
                                                <li className="border rounded-full py-1 px-4 mt-3 font-mono italic text-sm">Website & App Design</li>
                                                <li className="border rounded-full py-1 px-4 mt-3 font-mono italic text-sm">Frontend Development</li>
                                            </ul>
                                        </div>
                                        <div className="ml-24">
                                            <h3 className="font-mono italic text-sm featured-color">Date</h3>
                                            <h4 className="mt-2">2018 – 2020</h4>
                                        </div>
                                    </div>
                                    <div className="mt-8 sm:mt-0 sm:w-2/5">
                                        <p className="leading-normal">My first day at NeverBounce, I was tasked with rebranding our company. Though we were already established as an industry-leader in the space, we wanted a new brand to take it to the next level, and take it to the next level we did! I worked directly with the Founder & CEO to evolve the brand and create an entirely new system. We hired an illustrator to create new imagery, revamped and redesigned our SAAS application, website and collateral from head-to-toe.</p>
                                        <p className="leading-normal mt-4">All of this hard work paid off when we were acquired by ZoomInfo in Fall 2018 and I was brought on board the ZoomInfo team to continue growing our brand presence.</p>
                                        <a href="https://www.neverbounce.com/" target="_blank" rel="noreferrer" className="mt-8 block featured-color hover:underline">Launch Website -></a>
                                    </div>
                                </div>
                                <FadeInSection>
                                    <div className="mt-16">
                                        <img src={rotate1} alt="project images"/>
                                    </div>
                                </FadeInSection>
                            </div>
                            <div className="extra-large-wrapper mt-16 sm:mt-32">
                                <div className="mt-16 sm:mt-32">
                                    <FadeInSection>
                                        <img src={logos} alt=""/>
                                    </FadeInSection>
                                </div>
                                <div className="mt-16 sm:mt-32 grid gap-8 lg:gap-16 sm:grid-cols-2 content-start">
                                        <div className="grid gap-8 lg:gap-16 content-start">
                                            <FadeInSection>
                                                <img src={homepage} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                            </FadeInSection>
                                            <FadeInSection>
                                                <img src={sync} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                            </FadeInSection>
                                        </div>
                                        <div className="grid gap-8 lg:gap-16 content-start">
                                            <FadeInSection>
                                                <img src={pricing} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                            </FadeInSection>
                                            <FadeInSection>
                                                <img src={clean} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                            </FadeInSection>
                                        </div>
                                </div>
                                <FadeInSection>
                                    <img src={macs} alt="before" className="mt-16 sm:mt-32 rounded-3xl"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={illos} alt="before" className="mt-16 sm:mt-32"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={cards} alt="before" className="mt-16 sm:mt-32"/>
                                </FadeInSection>
                                <FadeInSection>
                                    <img src={deck} alt="before" className="mt-16 sm:mt-32"/>
                                </FadeInSection>
                                    <div className="grid gap-8 mt-16 sm:mt-32 sm:grid-cols-2">
                                        <FadeInSection>
                                            <img src={poster} alt="before" className=""/>
                                        </FadeInSection>
                                        <FadeInSection>
                                            <img src={bag} alt="before" className=""/>
                                        </FadeInSection>
                                    </div>
                            </div>
                        </section>
                        <section>
                            <FadeInSection>
                                <div className="extra-large-wrapper text-center relative mt-16 sm:mt-32 sm:mb-16">
                                    <Link to="/work/thrivable" className="text-3xl sm:text-6xl font-serif border-b-2 border-white hover:border-black">
                                        next project — thrivable
                                    </Link>
                                </div>
                            </FadeInSection>
                        </section>
                    </Container>
                </Layout>
        );
    }
}

export default NeverBounce;
